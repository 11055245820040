@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

@font-face {
    font-family: F1;
    font-display: auto;
    src: url(assets/fonts/Formula1/Formula1-Regular.woff2)
    format("woff2"),
    url(assets/fonts/Formula1/Formula1-Regular.woff)
    format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: F1;
    font-display: auto;
    src: url(assets/fonts/Formula1/Formula1-Italic.woff2)
    format("woff2"),
    url(assets/fonts/Formula1/Formula1-Italic.woff)
    format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: F1Bold;
    font-display: auto;
    src: url(/src/assets/fonts/Formula1/Formula1-Bold.woff2)
    format("woff2"),
    url(/src/assets/fonts/Formula1/Formula1-Bold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: F1Black;
    font-display: auto;
    src: url(assets/fonts/Formula1/Formula1-Black.woff2)
    format("woff2"),
    url(assets/fonts/Formula1/Formula1-Black.woff)
    format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: F1Wide;
    font-display: auto;
    src: url(assets/fonts/Formula1/Formula1-Wide.woff2)
    format("woff2"),
    url(assets/fonts/Formula1/Formula1-Wide.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: F1YEAR;
    font-display: auto;
    src: url(assets/fonts/Formula1/F1YEARRegular.woff2)
    format("woff2"),
    url(assets/fonts/Formula1/F1YEARRegular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.react-datetime-picker__wrapper {
    border: none !important;
}
.react-datetime-picker--disabled {
    opacity: 0.70;
    background-color: rgb(71, 85, 105) !important;
    color:  rgb(255, 255, 255) !important;
}