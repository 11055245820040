.standings {
  thead tr th:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  thead tr th:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  tbody::before {
    height: 30px;
    display: table-row;
    content: '';
  }

  tbody tr:first-child td:first-child {
    border-radius: 0.25rem 0 0 0;
  }

  tbody tr:first-child td:last-child {
    border-radius: 0 0.25rem 0 0;
  }

  tbody tr:last-child td:first-child {
    border-radius: 0 0 0 0.25rem;
  }

  tbody tr:last-child td:last-child {
    border-radius: 0 0 0.25rem 0;
  }
}
